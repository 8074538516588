

import * as React from "react"

import { useContext, useState, useEffect } from "react";

import { Link } from "gatsby"

import { muiCache } from "../../cache";

import { Link as LinkMUI } from "@mui/material"

import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { Stack } from "@mui/material/";
import { createTheme } from "@mui/material/";
import { ThemeProvider } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

import { CSSTransitionGroup } from 'react-transition-group'

import "../styles/layout.css"
import "../styles/funkyLink.css"

import "@fontsource/open-sans";
import "@fontsource/zen-kaku-gothic-new"
import Logo from "../data/images/logo.png"


import MenuIcon from '@mui/icons-material/Menu';
import NoSsr from '@mui/base/NoSsr';
import { CacheProvider } from "@emotion/react";


const themeOptions = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#06d0ff',
        },
        secondary: {
            main: '#0090f5',
        },
        background: {
            default: '#1a1a1a',
            paper: '#000000',
        },
        text: {
            primary: '#f9fafb',
        },
    },
    typography: {
        fontFamily: 'Zen Kaku Gothic New',

        fontSize: 16,
        fontWeightLight: 300,
        mixBlendMode: "difference",
        willChange: "opacity"
    },
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
        Link: {

        },
        MuiButton: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(45deg, #179ffa 30%, #13ffa4 90%)',
                    border: 0,
                    borderRadius: 8,
                    // boxShadow: '0 3px 5px 2px #285157ff',
                    fontFamily: "Open Sans",
                    color: 'black',
                    fontSize: 18,
                    fontWeight: "bold",
                    height: 54,
                    padding: '0 30px',
                },
            }
        },
    },
});

const linkStyle = {
    // textShadow: "1px 1px 0px black, 2px 2px 0px #5900ff8c, 3px 3px 0px black, 4px 4px 0px #3a006933"
    fontSize: 20
}



function LinksDisplay(props) {

    const [drawerState, setDrawerState] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerState(open);
    };


    const links = {
        Home: "/",
        Music: "/music/",
        "Socials / Links": "/links/",
        About: "/about/"
    }

    if (props.mobile) {
        return (
            <Stack spacing={2} flexDirection={"row"} justifyContent="right" alignItems="center">
                <MenuIcon color="primary" onClick={toggleDrawer(true)}></MenuIcon>
                <Drawer
                    anchor="right"
                    open={drawerState}
                    onClose={toggleDrawer(false)}
                >
                    <Stack spacing={3} margin={2} px={2}>
                        <Link to="/" onClick={toggleDrawer(false)}><img className="logo" style={{ height: "130px", imageRendering: "pixelated" }} src={Logo} alt="Logo" /></Link>
                        {Object.keys(links).map(linkName => {
                            return (
                                <LinkMUI component={Link} className={"funkyLinks"} to={links[linkName]} onClick={toggleDrawer(false)} underline="none"> <Typography style={linkStyle} color="white">{linkName}</Typography>   </LinkMUI>
                            )
                        })}
                    </Stack>

                </Drawer>
            </Stack>

        )
    } else {
        return (
            <Grid container spacing={2} flexDirection={"row"} justifyContent="flex-end" alignItems="center">
                {
                    Object.keys(links).map(linkName => {
                        return (
                            <Grid item px={3}> <LinkMUI component={Link} className={"funkyLinks"} to={links[linkName]} underline="none"> <Typography style={linkStyle} color="white">{linkName}</Typography>   </LinkMUI>   </Grid>
                        )
                    })
                }
            </Grid>
        )
    }
}



function BackgroundIFrame({ path }) {

    const [hide, setHide] = useState(false)
    const [src, setSrc] = useState("https://3d.davz.com/planets")

    React.useEffect(() => {
        setSrc((path == "/music/diamonddust/") ? "https://3d.davz.com/eg"
            : "https://3d.davz.com/planets")
        // setHide(false)
        if (path == "/music/diamonddust/") {
            // ignore it LOL
        } else {
            document.getElementById("backIFrame").contentWindow.postMessage(
                {
                    type: "camera_state",
                    message: path
                },
                "https://3d.davz.com/*"
            );
        }
    }, [path])

    return (<CSSTransitionGroup transitionName="backIFrameS"
        transitionEnterTimeout={1500}
        transitionLeaveTimeout={700}
    >
        <iframe
            id="backIFrame"
            src={src}
            key={src}
            onLoad={() => {
                setTimeout(() => {
                    document.getElementById("backIFrame").contentWindow.postMessage(
                        {
                            type: "camera_state",
                            message: window.location.pathname
                        },
                        "https://3d.davz.com/*"
                    );
                }, 500)
                setTimeout(() => {
                    setHide(false)
                }, 200)

            }}

            style={{
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                margin: "0px",
                padding: "0px",
                border: "0px",
                zIndex: -1,
                position: "fixed",
            }}
        ></iframe>

    </CSSTransitionGroup>);
}


function Layout(props) {
    const mobile = useMediaQuery('(max-width: 630px)');

    return (
        <>
            <NoSsr>
                <div style={{ height: "100vh", minHeight: "100%" }}>
                    {/* <CacheProvider value={muiCache}> */}
                    <ThemeProvider theme={themeOptions}>
                        <Grid container justifyContent="space-between" alignItems="center" flexDirection={"row"} xs>
                            {
                                !mobile
                                    ? <Grid item marginLeft={4}>
                                        <Link to="/"><img className="logo" style={{ height: "130px", imageRendering: "pixelated" }} src={Logo} alt="Logo" /></Link>
                                    </Grid>
                                    : <Grid item marginLeft={4} style={{ height: "130px" }}>

                                    </Grid>
                            }

                            <Grid item marginRight={6} xs paddingLeft={4}>
                                <LinksDisplay mobile={mobile}></LinksDisplay>
                            </Grid>
                        </Grid>

                        <Grid item style={{}}>
                            {props.children}
                        </Grid>

                        <Grid item style={{ height: "130px" }} container justifyContent={"center"} alignItems={"center"}>
                            <Typography
                                component="p"
                                align="center"
                                color="white" className="shadow"
                                p={6}
                            >
                                © 2022 DavZ
                            </Typography>
                        </Grid>

                        <BackgroundIFrame path={props.path}></BackgroundIFrame>
                    </ThemeProvider>
                    {/* </CacheProvider> */}
                </div>
            </NoSsr>
        </>
    );
}

export default Layout;